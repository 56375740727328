export const box = {
  up: [
    {
      name: 'cabinete3',
      size: 15,
      color: '#003300',
      alignment: null,
      id: 1,
      water: false,
    },
    {
      name: 'cabinete3',
      size: 15,
      color: '#003300',
      alignment: null,
      id: 1,
      water: false,
    },
    {
      name: 'cabinete3',
      size: 15,
      color: '#003300',
      alignment: null,
      id: 1,
      water: false,
    },
    null,
    {
      name: 'sink hole',
      size: 15,
      color: '#0000FF',
      alignment: null,
      id: 0,
      water: false,
    },
    {
      name: 'sink hole',
      size: 15,
      color: '#0000FF',
      alignment: null,
      id: 0,
      water: true,
    },
    {
      name: 'sink hole',
      size: 15,
      color: '#0000FF',
      alignment: null,
      id: 0,
      water: false,
    },
    {
      name: 'sink hole',
      size: 15,
      color: '#0000FF',
      alignment: null,
      id: 0,
      water: false,
    },
    {
      name: 'cabinete3',
      size: 15,
      color: '#003300',
      alignment: null,
      id: 2,
      water: false,
    },
    {
      name: 'cabinete3',
      size: 15,
      color: '#003300',
      alignment: null,
      id: 2,
      water: false,
    },
    {
      name: 'cabinete3',
      size: 15,
      color: '#003300',
      alignment: null,
      id: 2,
      water: false,
    },
    {
      name: 'cabinete3',
      size: 15,
      color: '#003300',
      alignment: null,
      id: 3,
      water: false,
    },
    {
      name: 'cabinete3',
      size: 15,
      color: '#003300',
      alignment: null,
      id: 3,
      water: false,
    },
    {
      name: 'cabinete3',
      size: 15,
      color: '#003300',
      alignment: null,
      id: 3,
      water: false,
    },
    {
      name: 'stove',
      size: 15,
      color: '#00FF00',
      alignment: null,
      id: 0,
      water: false,
    },
    {
      name: 'stove',
      size: 15,
      color: '#00FF00',
      alignment: null,
      id: 0,
      water: false,
    },
    {
      name: 'stove',
      size: 15,
      color: '#00FF00',
      alignment: null,
      id: 0,
      water: false,
    },
    {
      name: 'stove',
      size: 15,
      color: '#00FF00',
      alignment: null,
      id: 0,
      water: false,
    },
    null,
    null,
    {
      name: 'fridge',
      size: 15,
      color: '#000000',
      alignment: null,
      id: 0,
      water: false,
    },
    {
      name: 'fridge',
      size: 15,
      color: '#000000',
      alignment: null,
      id: 0,
      water: false,
    },
    {
      name: 'fridge',
      size: 15,
      color: '#000000',
      alignment: null,
      id: 0,
      water: false,
    },
    {
      name: 'fridge',
      size: 15,
      color: '#000000',
      alignment: null,
      id: 0,
      water: false,
    },
  ],
  bottom: [
    null,
    null,
    {
      name: 'sink hole',
      size: 15,
      color: '#0000FF',
      alignment: null,
      id: 0,
      water: false,
    },
    {
      name: 'sink hole',
      size: 15,
      color: '#0000FF',
      alignment: null,
      id: 0,
      water: false,
    },
    {
      name: 'sink hole',
      size: 15,
      color: '#0000FF',
      alignment: null,
      id: 0,
      water: false,
    },
    {
      name: 'sink hole',
      size: 15,
      color: '#0000FF',
      alignment: null,
      id: 0,
      water: false,
    },
    {
      name: 'sink hole',
      size: 15,
      color: '#0000FF',
      alignment: null,
      id: 0,
      water: false,
    },
    {
      name: 'sink hole',
      size: 15,
      color: '#0000FF',
      alignment: null,
      id: 0,
      water: false,
    },
    null,
    null,
    null,
    null,
    null,
    null,
    {
      name: 'stove',
      size: 15,
      color: '#00FF00',
      alignment: null,
      id: 0,
      water: false,
    },
    {
      name: 'stove',
      size: 15,
      color: '#00FF00',
      alignment: null,
      id: 0,
      water: false,
    },
    {
      name: 'stove',
      size: 15,
      color: '#00FF00',
      alignment: null,
      id: 0,
      water: false,
    },
    {
      name: 'stove',
      size: 15,
      color: '#00FF00',
      alignment: null,
      id: 0,
      water: false,
    },
    null,
    null,
    {
      name: 'fridge',
      size: 15,
      color: '#000000',
      alignment: null,
      id: 0,
      water: false,
    },
    {
      name: 'fridge',
      size: 15,
      color: '#000000',
      alignment: null,
      id: 0,
      water: false,
    },
    {
      name: 'fridge',
      size: 15,
      color: '#000000',
      alignment: null,
      id: 0,
      water: false,
    },
    {
      name: 'fridge',
      size: 15,
      color: '#000000',
      alignment: null,
      id: 0,
      water: false,
    },
  ],
  high: [
    {
      name: 'cabinete3',
      size: 15,
      color: '#003300',
      alignment: null,
      id: 1,
      water: false,
    },
    {
      name: 'cabinete3',
      size: 15,
      color: '#003300',
      alignment: null,
      id: 1,
      water: false,
    },
    {
      name: 'cabinete3',
      size: 15,
      color: '#003300',
      alignment: null,
      id: 1,
      water: false,
    },
    null,
    null,
    null,
    null,
    null,
    {
      name: 'cabinete3',
      size: 15,
      color: '#003300',
      alignment: null,
      id: 2,
      water: false,
    },
    {
      name: 'cabinete3',
      size: 15,
      color: '#003300',
      alignment: null,
      id: 2,
      water: false,
    },
    {
      name: 'cabinete3',
      size: 15,
      color: '#003300',
      alignment: null,
      id: 2,
      water: false,
    },
    {
      name: 'cabinete3',
      size: 15,
      color: '#003300',
      alignment: null,
      id: 3,
      water: false,
    },
    {
      name: 'cabinete3',
      size: 15,
      color: '#003300',
      alignment: null,
      id: 3,
      water: false,
    },
    {
      name: 'cabinete3',
      size: 15,
      color: '#003300',
      alignment: null,
      id: 3,
      water: false,
    },
    {
      name: 'ventilation',
      size: 15,
      color: '#FFC0CB',
      alignment: null,
      id: 0,
      water: false,
    },
    {
      name: 'ventilation',
      size: 15,
      color: '#FFC0CB',
      alignment: null,
      id: 0,
      water: false,
    },
    {
      name: 'ventilation',
      size: 15,
      color: '#FFC0CB',
      alignment: null,
      id: 0,
      water: false,
    },
    {
      name: 'ventilation',
      size: 15,
      color: '#FFC0CB',
      alignment: null,
      id: 0,
      water: false,
    },
    null,
    null,
    {
      name: 'fridge',
      size: 15,
      color: '#000000',
      alignment: null,
      id: 0,
      water: false,
    },
    {
      name: 'fridge',
      size: 15,
      color: '#000000',
      alignment: null,
      id: 0,
      water: false,
    },
    {
      name: 'fridge',
      size: 15,
      color: '#000000',
      alignment: null,
      id: 0,
      water: false,
    },
    {
      name: 'fridge',
      size: 15,
      color: '#000000',
      alignment: null,
      id: 0,
      water: false,
    },
  ],
  mesage: null,
  cart: [],
}
