const equals = items => {
  if (items.length === 0) return []

  let prev = items[0]
  let counter = 1
  let arr = []

  for (let i = 1; i < items.length; ++i) {
    if (items[i] == prev) {
      counter += 1
    } else {
      arr.push(counter)
      counter = 1
      prev = items[i]
    }
  }

  arr.push(counter)

  return arr
}
export const resolveBoxes = items => {
  // return [
  //   {
  //     name: 'cabinete3',
  //     size: 3,
  //   },
  //   { name: null, size: 1 },
  //   {
  //     name: 'sink hole',
  //     size: 4,
  //   },
  //   {
  //     name: 'cabinete3',
  //     size: 3,
  //   },
  //   {
  //     name: 'cabinete3',
  //     size: 3,
  //   },
  //   {
  //     name: 'stove',
  //     size: 4,
  //   },
  //   { name: null, size: 2 },
  //   {
  //     name: 'fridge',
  //     size: 4,
  //   },
  // ]

  // making a unique key name and id
  const uniqueItems = items.map(item => item && `${item.name}-${item.id}`)
  const ret = equals(uniqueItems).reduce(
    (acc, size) => {
      const sum = acc.sum + size

      return {
        sum,
        arr: acc.arr.concat([
          {
            name: items[sum - 1] && items[sum - 1].name,
            size,
          },
        ]),
      }
    },
    { sum: 0, arr: [] }
  ).arr

  // console.log(ret)

  return ret
}

// Adds the image according to the the name and the size of the box
export const resolveImage = (
  boxImageMap = {
    'cabinete3-2': 'Cabinet with door, 1 drawer, 30cm, 1.png',
    'cabinete3-3': 'Cabinet with door, 1 drawer, 45cm, 1.png',
    'cabinete3-4': 'Cabinet with door, 1 drawer, 60cm, 1.png',
    'cabinete3-6': 'Cabinet with door, 1 drawer, 90cm, 1.png',
    'cabinete-2': 'Cabinet with door, 30cm, 1.png',
    'cabinete-3': 'Cabinet with door, 45cm, 1.png',
    'cabinete-4': 'Cabinet with door, 60cm, 1.png',
    'cabinete-6': 'Cabinet with door, 90cm, 1.png',
    'cabinete2-2': 'Cabinet with door, 1 drawer, 30cm, 1.png',
    'cabinete4-4': 'Cabinet with door, 60cm, 1.png',
    'cabinete6-6': 'Cabinet with door, 90cm, 1.png',
    'dish washer-3': 'Dishwasher, 45cm, 1.png',
    'dish washer-4': 'Dishwasher, 60cm, 1.png',
    // TODO: washer-4 pending
    'washer-4': 'Dishwasher, 60cm, 1.png',
    'fridge-4': 'Fridge, integrate, end of kitchen.png',
    // TODO: ventilation-4, ventilation-6
    'ventilation-4': 'Hanging Cabinet, 1 Liftdoor, 60cm.png',
    'ventilation-6': 'Hanging Cabinet, 1 Liftdoor, 90cm.png',
    // TODO: oven-3 pending
    'oven-3': 'Oven in high cabinet, 60cm.png',
    'oven-4': 'Oven in high cabinet, 60cm.png',
    'sink hole-4': 'Sink, 60cm hole, 60cm, 1.png',
    'stove-4': 'Stove single, 60cm, 1.png',
    'stove-6': 'Stove single, 90cm, 1.png',
  }
) => ({ name, size }) => {
  const image = name && boxImageMap[`${name}-${size}`]

  if (name && image == void 0)
    console.warn(`${name} of size ${size}: does not exists!`)

  return {
    name,
    size,
    image,
  }
}
