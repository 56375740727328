var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"px-4 px-md-12",staticStyle:{"min-height":"1000px","overflow":"hidden"}},[_c('div',{staticClass:"mx-auto py-6 pb-10 pt-md-10 container",staticStyle:{"min-width":"900px"}},[_c('div',{staticStyle:{"position":"relative"},style:({
        transform: _vm.$vuetify.breakpoint.mdAndUp
          ? 'scale(1) translate(0, 0)'
          : _vm.$vuetify.breakpoint.smAndUp
          ? 'scale(0.6) translate(-30%, -30%)'
          : 'scale(0.3) translate(-100%, -100%)',
      })},[(_vm.box1 == void 0)?_c('v-progress-circular',{staticClass:"mx-auto mt-16 d-block",attrs:{"indeterminate":"","color":"primary"}}):[_c('div',{staticStyle:{"position":"absolute","display":"flex","align-items":"flex-start"}},[_vm._l((_vm.box1),function(item,index){return [(item.name != void 0)?_c('div',{key:index,staticClass:"d-inline-block",style:({
                'max-width': (((92 * item.size) / 3) + "px"),
                transform: ("translateY(" + (index * 30) + "px)"),
              })},[_c('img',{staticClass:"d-inline-block",attrs:{"src":("/statics/2. 3D Drawings/" + (item.image))}})]):_c('div',{key:index,staticClass:"d-inline-block",style:({ width: ((item.size * _vm.bSpace) + "px"), height: '1px' })})]})],2),_c('div',{staticStyle:{"min-height":"650px","display":"flex","align-items":"flex-end"}},[_vm._l((_vm.box2),function(item,index){return [(item.name != void 0)?_c('div',{key:index,staticClass:"d-inline-block",style:({
                'max-width': (((92 * item.size) / 3) + "px"),
                transform: ("translateY(" + (index * 30) + "px)"),
              })},[_c('img',{staticClass:"d-inline-block",attrs:{"src":("/statics/2. 3D Drawings/" + (item.image))}})]):_c('div',{key:index,staticClass:"d-inline-block",style:({ width: ((item.size * _vm.bSpace) + "px"), height: '1px' })})]})],2)]],2)])])}
var staticRenderFns = []

export { render, staticRenderFns }