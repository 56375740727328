<script>
import { box } from '@/common/data.js'
import { resolveBoxes, resolveImage } from '@/common/core.js'

export default {
  data() {
    return {
      bSpace: 64 / 2,
      box,
      box1: null,
      box2: null,
    }
  },
  created() {
    const data =
      (this.$route.query.data && JSON.parse(this.$route.query.data)) || box
    console.log(data)

    this.box1 = resolveBoxes(
      data.high.map(
        item =>
          item &&
          // remove fridge, oven on top
          (['fridge', 'oven'].includes(item.name)
            ? { ...item, name: null }
            : item)
      )
    ).map(resolveImage())
    console.log(this.box1)

    this.box2 = resolveBoxes(data.up).map(resolveImage())
    console.log(this.box2)
  },
}
// 3 - 92
// 4 - x
</script>
<template>
  <div class="px-4 px-md-12" style="min-height: 1000px; overflow: hidden;">
    <div
      class="mx-auto py-6 pb-10 pt-md-10 container"
      style="min-width: 900px;"
    >
      <div
        style="position: relative;"
        :style="{
          transform: $vuetify.breakpoint.mdAndUp
            ? 'scale(1) translate(0, 0)'
            : $vuetify.breakpoint.smAndUp
            ? 'scale(0.6) translate(-30%, -30%)'
            : 'scale(0.3) translate(-100%, -100%)',
        }"
      >
        <v-progress-circular
          v-if="box1 == void 0"
          indeterminate
          color="primary"
          class="mx-auto mt-16 d-block"
        ></v-progress-circular>
        <template v-else>
          <div
            style="position: absolute; display: flex; align-items: flex-start;"
          >
            <template v-for="(item, index) in box1">
              <div
                v-if="item.name != void 0"
                :key="index"
                class="d-inline-block"
                :style="{
                  'max-width': `${(92 * item.size) / 3}px`,
                  transform: `translateY(${index * 30}px)`,
                }"
              >
                <img
                  :src="`/statics/2. 3D Drawings/${item.image}`"
                  class="d-inline-block"
                />
              </div>
              <div
                v-else
                :key="index"
                class="d-inline-block"
                :style="{ width: `${item.size * bSpace}px`, height: '1px' }"
              ></div>
            </template>
          </div>

          <div style="min-height: 650px; display: flex; align-items: flex-end;">
            <template v-for="(item, index) in box2">
              <div
                v-if="item.name != void 0"
                :key="index"
                class="d-inline-block"
                :style="{
                  'max-width': `${(92 * item.size) / 3}px`,
                  transform: `translateY(${index * 30}px)`,
                }"
              >
                <img
                  :src="`/statics/2. 3D Drawings/${item.image}`"
                  class="d-inline-block"
                />
              </div>
              <div
                v-else
                :key="index"
                class="d-inline-block"
                :style="{ width: `${item.size * bSpace}px`, height: '1px' }"
              ></div>
            </template>
          </div>
        </template>
      </div>
    </div>
  </div>
</template>
